.navbar {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fredoka One', cursive;
  border: none;
}
.navbar-black {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Fredoka One', cursive;
  border: none;
}
.menu-btn {
  padding: 10px;
  color: #ff0278;
  font-size: medium;
}
.counter {
  display: flex;
  padding: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: medium;
}
