/* width */
::-webkit-scrollbar {
  background: #ff0278;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00bdff;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #87dfff;
}

.kitchen {
  display: flex;
  flex-direction: column;
  background: none;
  font-family: 'Fredoka One', cursive;
  margin-left: 5%;
  margin-right: 5%;
}
.kitchen-black {
  display: flex;
  flex-direction: column;
  background: none;
  font-family: 'Fredoka One', cursive;
  margin-left: 5%;
  margin-right: 5%;
}

.ingredient-picker {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

.recipe-item {
  display: flex;
  align-items: baseline;
  background: none;
  border: none;
  cursor: pointer;
  color: grey;
}

.delete-btn {
  color: red;
  border: none;
  background: none;
  font-weight: bold;
  padding: 5px;
}
.name-txt {
  border: none;
  background: none;
}

.ingredient-warning {
  color: red;
  font-family: 'Fredoka One', cursive;
  padding-top: 10px;
}

.wallet-warning {
  color: red;
  font-family: 'Fredoka One', cursive;
  padding: 10px;
}
.wallet-success {
  color: green;
  font-family: 'Fredoka One', cursive;
  padding: 10px;
}

.space {
  padding-top: 10px;
}

.card-body {
  display: flex;
  color: white;
  font-size: large;
  background-color: crimson;
  justify-content: center;
  margin-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chef-details {
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-family: 'Fredoka One', cursive;
  font-size: medium;
  color: white;
}

.chef-item {
  padding-right: 20px;
}
.center {
  display: flex;
  width: 160px;
  justify-content: center;
}

.ingredient-layer-kitchen {
  display: flex;
  position: absolute;
  width: 400px;
  z-index: 10;
}

.chef-details {
  flex-direction: row;
}

.border-image {
  border-radius: 2em;
  border: 3px solid transparent;
  margin: 12px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 8px 8px 0px 4px #00bdff;
  background-color: white;
}
.border-image-black {
  border-radius: 2em;
  border: 3px solid transparent;
  margin: 12px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 8px 8px 0px 4px #00bdff;
  background-color: black;
}

.border-howto {
  border-radius: 2em;
  border: 3px solid transparent;
  margin: 10px;
  cursor: pointer;
  box-shadow: 8px 8px 0px 4px #ff0278;
  width: 98%;
  background-color: white;
}
.border-howto-black {
  border-radius: 2em;
  border: 3px solid transparent;
  margin: 10px;
  cursor: pointer;
  box-shadow: 8px 8px 0px 4px #ff0278;
  width: 98%;
  background-color: black;
}

.border-image:hover {
  transform: scale(110%);
  margin: 11px;
  background: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255))
      padding-box,
    linear-gradient(
        45deg,
        rgb(255, 0, 0),
        rgb(255, 230, 0),
        rgb(0, 254, 21),
        rgb(0, 234, 255),
        rgb(0, 17, 255),
        rgb(255, 0, 230)
      )
      border-box;
  border-radius: 2em;
  border: 4px solid transparent;
  animation: item 8s ease infinite;

  background-size: 500% 500%;
  cursor: pointer;
  box-shadow: 8px 8px 0px 4px #ff0278;
}

.border-image-black:hover {
  transform: scale(110%);
  margin: 11px;

  background: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)) padding-box,
    linear-gradient(
        45deg,
        rgb(255, 0, 0),
        rgb(255, 230, 0),
        rgb(0, 254, 21),
        rgb(0, 234, 255),
        rgb(0, 17, 255),
        rgb(255, 0, 230)
      )
      border-box;
  border-radius: 2em;
  border: 4px solid transparent;
  animation: item 8s ease infinite;

  background-size: 500% 500%;
  cursor: pointer;
  box-shadow: 8px 8px 0px 4px #ff0278;
}

@keyframes item {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
.top-luck {
  background: linear-gradient(45deg, #fec627, #00bdff, #ff0278);
  animation: item 16s ease infinite;

  background-size: 1000% 1000%;
}
.mint-btn {
  color: white;
  background: #ff0278;
  border-radius: 10px;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  width: 128px;
  margin: 5px;
  justify-content: center;
}
.mint-btn:hover {
  transform: scale(110%);
}

.mint-alt-btn {
  color: white;
  background: #00bdff;
  border-radius: 10px;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  width: 128px;
  margin: 5px;
  justify-content: center;
}
.mint-alt-btn:hover {
  transform: scale(110%);
}
.luck-btn {
  color: white;
  background: #ff0278;
  border-radius: 10px;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  width: 256px;
  margin: 5px;
  justify-content: center;
}
.luck-btn:hover {
  transform: scale(110%);
}
