.franchise-page {
  font-family: 'Sniglet', cursive;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
}
.franchise-page-black {
  font-family: 'Sniglet', cursive;
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.franchise-frame {
  padding-top: 10px;
  font-family: 'Sniglet', cursive;
  font-weight: bold;
  border-color: #00bdff;
  border-width: 5px;
  border-radius: 10px;
  border-style: solid;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-franchise-menu {
  padding: 10px;
}

.regular {
  width: 140px;
  color: #ff0278;
  font-weight: bold;
  background: none;
}

.wrapper-franchise {
  width: 140px;
  animation: rainbow 6s ease infinite;
  background: linear-gradient(
    135deg,
    #844200,
    #f5af0c 30%,
    white,
    #f5af0c,
    #844200
  );
  background-size: 100% 200%;
  min-height: 360px;
}

@keyframes rainbow {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.ingredient-key {
  display: flex;
  justify-content: center;
  padding-top: 40%;
}

.top-luck {
  background: linear-gradient(45deg, #fec627, #00bdff, #ff0278);
  animation: item 16s ease infinite;

  background-size: 1000% 1000%;
}

.ingredient-layer-franchise {
  z-index: 1;
  position: absolute;
  width: 140px;
  padding: 5px;
}

.ingredient-layer-myfranchise {
  z-index: 1;
  position: absolute;
  width: 100%;
}
.arrow-left {
  transform: scale(100%);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  color: #ff0278;
  font-size: 25px;
}
.arrow-right {
  transform: scale(100%);
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  color: #ff0278;
  font-size: 25px;
}

.arrow-right:hover {
  transform: scale(110%);
}
.arrow-left:hover {
  transform: scale(110%);
}
.key:hover {
  transform: scale(120%);
}
.small-icons-franchise {
  height: 30px;
}

.items-titles-franchise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.send-btn {
  color: white;
  background: #ff0278;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  z-index: 1;
  border-radius: 10px;
  min-width: 96px;
  width: 100%;
}

.send-btn:hover {
  z-index: 1;
  transform: scale(110%);
}

.claim-btn {
  border: none;
  border-radius: 10px;
  background-color: red;
  color: white;
  font-size: smaller;
}

.claim-btn:hover {
  z-index: 1;
  font-size: small;
}

.next-btn {
  color: white;
  background: #00bdff;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  z-index: 1;
  border-radius: 10px;
}

.next-btn:hover {
  z-index: 1;
  transform: scale(110%);
}

.about-btn {
  color: white;
  background: #ff0278;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  z-index: 10;
  border-radius: 10px;
  min-height: 96px;
  align-items: center;
}

.about-btn:hover {
  z-index: 1;
  transform: scale(110%);
}

.unlock-btn {
  color: white;
  background: #ff0278;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  z-index: 1;
  border-radius: 10px;
  width: 240px;
}

.unlock-btn:hover {
  z-index: 1;
  transform: scale(110%);
}
