.about {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10%;
  padding-right: 10%;
  font-family: 'Sniglet', cursive;
  color: grey;
}
.about-black {
  display: flex;
  flex-direction: column;
  background-color: black;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10%;
  padding-right: 10%;
  font-family: 'Sniglet', cursive;
  color: grey;
}
