.market {
  display: flex;
  background: none;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: 'Sniglet', cursive;
}

.market-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: 'Sniglet', cursive;
}

.market-black {
  display: flex;
  background: none;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: 'Sniglet', cursive;
}

.market-stats-black {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: black;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: 'Sniglet', cursive;
}

.market-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.switch-btn {
  padding-left: 25px;
  border-radius: 10px;
  color: aqua;
}
.market-txt {
  padding-left: 25px;
}

.ingredient-imgs {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 5px;
  padding-top: 5px;
}

.ingredient-layer {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  width: 100%;
  padding-right: 5px;
  padding-bottom: 5px;
}

.items-titles {
  display: flex;
  flex-direction: column;
}

.items-labels {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.item-info {
  display: flex;
  flex-direction: column;
}

.title-num {
  color: white;
  padding-left: 10px;
}
.small-icons {
  display: flex;
  padding-top: 10px;
  padding-left: 5px;
  height: 50px;
}
.ingredient-layer-market {
  display: flex;
  position: absolute;
  width: 100%;
}

.buy-btn {
  color: white;
  background: #ff0278;
  border-radius: 10px;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
}
.buy-btn:hover {
  transform: scale(110%);
}

.wrapper {
  animation: rainbow 6s ease infinite;
  background: linear-gradient(
    135deg,
    #844200,
    #f5af0c 30%,
    white,
    #f5af0c,
    #844200
  );
  background-size: 100% 200%;
}

@keyframes rainbow {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
