.main {
  display: flex;
  background: white;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: 'Sniglet', cursive;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}
.main-black {
  display: flex;
  background: black;
  padding-top: 25px;
  padding-bottom: 25px;
  font-family: 'Sniglet', cursive;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.owned-title {
  display: flex;
  flex-direction: column;
}

.name-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
}

.market-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  position: absolute;
  left: 100%;
}

.items-labels {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #ff0278;
}
.item-info {
  display: flex;
  flex-direction: column;
}

.title-num {
  color: white;
  padding-left: 10px;
}
.small-icons {
  display: flex;
  padding-top: 10px;
  padding-left: 5px;
  height: 50px;
}

.regular-mypizza {
  width: 140px;
  color: #ff0278;
  font-weight: bold;
  background: none;
}
.wrapper {
  animation: rainbow 6s ease infinite;
  background: linear-gradient(
    135deg,
    #844200,
    #f5af0c 30%,
    white,
    #f5af0c,
    #844200
  );
  background-size: 100% 200%;
}

@keyframes rainbow {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.ingredient-layer-myrecipe {
  display: flex;
  position: absolute;
  width: 100%;
}

.list-btn {
  color: white;
  background: #ff0278;
  border-radius: 10px;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  padding: 10;
  margin: 10;
}
.list-btn:hover {
  transform: scale(110%);
}

.show-btn {
  color: white;
  background: #ff0278;
  border-radius: 10px;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  margin: 10px;
}
.show-btn:hover {
  transform: scale(105%);
}
