.my-nfts {
  font-family: 'Sniglet', cursive;
  background-color: none;
  color: black;
  display: flex;
  justify-content: center;
}
.my-nfts-black {
  font-family: 'Sniglet', cursive;
  background-color: none;
  color: white;
  display: flex;
  justify-content: center;
}
.ingredient-layer-mynfts {
  z-index: 1;
  position: absolute;
  width: 140px;
}

.info-btn {
  color: white;
  background: #ff0278;
  padding: 10px;
  border: solid;
  border-color: black;
  cursor: pointer;
  display: flex;
  z-index: 10;
  border-radius: 10px;
  align-items: center;
  margin: 10px;
}

.info-btn:hover {
  z-index: 1;
  transform: scale(110%);
}
