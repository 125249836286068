.footer {
  display: flex;
  padding: 10px;
  justify-content: center;
  color: black;
  background-color: white;
  font-family: 'Kalam', cursive;
  bottom: 0;
}

.footer-black {
  display: flex;
  padding: 10px;
  justify-content: center;
  color: black;
  background-color: black;
  font-family: 'Kalam', cursive;
  bottom: 0;
}
